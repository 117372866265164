<template></template>
<script>
export default {
  name: 'frame',
  data() {
    return {
    }
  }
}
</script>
